<template>
  <div id="user-fields">
    <div class="row">
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Your Name <span class="text-danger">*</span></label>
          <input name="name"
            id="firstname"
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.name.$error, }"
            placeholder="First Name :"
            v-model.trim="$v.form.user.name.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.name.$error, }">
            <span v-if="!$v.form.user.name.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Last Name <span class="text-danger">*</span></label>
          <input name="name"
            id="lastname"
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user. e.$error, }"
            placeholder="Last Name :"
            v-model.trim="$v.form.user.lastName.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.lastName.$error, }">
            <span v-if="!$v.form.user.lastName.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Company Name
            <span class="text-danger">*</span>
          </label>
          <input name="name"
            id="companyname"
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.companyName.$error, }"
            placeholder="Company Name :"
            v-model.trim="$v.form.user.companyName.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.companyName.$error, }">
            <span v-if="!$v.form.user.companyName.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Street address
            <span class="text-danger">*</span></label>
          <input type="text"
            name="address1"
            id="address1"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.address.$error, }"
            placeholder="House number and street name :"
            v-model.trim="$v.form.user.address.$model"/>
            <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.address.$error, }">
              <span v-if="!$v.form.user.address.required">This field is required</span>
            </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-md-6">
        <div class="form-group position-relative">
          <label>Town / City <span class="text-danger">*</span></label>
          <input type="text"
            name="city"
            id="city"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.city.$error, }"
            placeholder="City Name :"
            v-model.trim="$v.form.user.city.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.city.$error, }">
            <span v-if="!$v.form.user.city.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-md-6">
        <div class="form-group position-relative">
          <label>Postal Code <span class="text-muted">(Optional)</span></label>
          <input type="text"
            name="postcode"
            id="postcode"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.zipCode.$error, }"
            placeholder="Zip :"
            v-model.trim="$v.form.user.zipCode.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.zipCode.$error, }">
            <span v-if="!$v.form.user.zipCode.numeric">This is no a valid zip code</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-md-6">
        <div class="form-group position-relative">
          <label>State <span class="text-danger">*</span></label>
          <input type="text"
            name="state"
            id="state"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.state.$error, }"
            placeholder="State Name :"
            v-model.trim="$v.form.user.state.$model"/>
            <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.state.$error, }">
              <span v-if="!$v.form.user.state.required">This field is required</span>
            </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-md-6">
        <div class="form-group position-relative">
          <label>Country <span class="text-danger">*</span></label>
          <select class="form-control custom-select"
            v-bind:class="{ 'is-invalid': $v.form.user.country.$error, }"
            v-model.trim="$v.form.user.country.$model">
            <option value="" disabled>--- Choose a country ---</option>
            <option v-for="(value, key) in countries" v-bind:key="key" v-bind:value="key">{{value}}</option>
          </select>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.country.$error, }">
            <span v-if="!$v.form.user.country.required">This field is required</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Phone <span class="text-danger">*</span></label>
          <input type="text"
            name="phone"
            id="phone"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.phoneNumber.$error, }"
            placeholder="State Name :"
            v-model.trim="$v.form.user.phoneNumber.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.phoneNumber.$error, }">
            <span v-if="!$v.form.user.phoneNumber.required">This field is required</span>
            <span v-if="!$v.form.user.phoneNumber.numeric">This is not a valid phone number</span>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-12">
        <div class="form-group position-relative">
          <label>Your Email <span class="text-danger">*</span></label>
          <input name="email"
            id="email"
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': $v.form.user.email.$error, }"
            placeholder="Your email :"
            v-model.trim="$v.form.user.email.$model"/>
          <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.user.email.$error, }">
            <span v-if="!$v.form.user.email.required">This field is required</span>
            <span v-if="!$v.form.user.email.email">This is not a valid email</span>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</template>

<script>
  import countries from "@/utils/countries";
  export default {
    props: {
      $v: Object,
    },
    data() {
      return {
        countries,
      };
    },
  };
</script>